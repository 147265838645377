import { ChartDownloaderProvider } from 'context/ChartDownloaderContext';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import store from 'store';
import { AppWrapper, Auth0Wrapper, IntercomWrapper } from 'wrappers';

export function App() {
  return (
    <AppWrapper>
      <Auth0Wrapper>
        <IntercomWrapper>
          <Provider store={store}>
            <ChartDownloaderProvider>
              <RouterProvider router={router} />
            </ChartDownloaderProvider>
          </Provider>
        </IntercomWrapper>
      </Auth0Wrapper>
    </AppWrapper>
  );
}
