import {
  EProgramVariation,
  ISkill,
  ProfileSkill,
} from '@guider-global/shared-types';
import {
  AvatarBox,
  ButtonStack,
  CollapsibleCard,
  ProgramMembershipDetailsView,
  Stack,
} from '@guider-global/ui';
import { format, isAfter } from 'date-fns';
import { useAdminRelationships, useAdminUserProfiles } from 'hooks';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgramMembershipEmptyTableView } from 'views';
import {
  IRelationshipsRow,
  RelationshipsTableContainer,
} from '../RelationshipsTableContainer';
import { useAppContext } from 'context';
import {
  buildSanityImageUrl,
  useOrganization,
} from '@guider-global/sanity-hooks';
import { SuggestedMatchesContainer } from '../SuggestedMatchesContainer';

export function OpenMatchingRelationshipsContainer() {
  const navigate = useNavigate();

  const { organizationSlug, isRootDomain } = useAppContext();

  // Profile

  const { adminUserProfiles } = useAdminUserProfiles({});
  const profiles = adminUserProfiles();
  const profile = profiles?.at(0);
  const userOrganization = profile?.organizationSlug ?? 'guider';

  const { organization } = useOrganization({
    organizationSlug: organizationSlug ?? userOrganization,
  });

  // Relationships

  const {
    adminRelationships: getRelationships,
    reqAdminRelationships,
    isLoadingAdminRelationships,
  } = useAdminRelationships({
    getSilently: false,
    forceRefresh: false,
  });
  const relationships = getRelationships();

  // Profile

  const profileData = useMemo(() => {
    if (!profile) return;
    const roles = profile.roles;

    const guideRole =
      organization?.open_matching?.program_type?.program_type_text?.common
        ?.guide?.singular ?? 'Mentor';

    const traineeRole =
      organization?.open_matching?.program_type?.program_type_text?.common
        ?.trainee?.singular ?? 'Mentee';

    const allSkills = [
      ...(organization?.open_matching?.skills?.soft_skills ?? []),
      ...(organization?.open_matching?.skills?.hard_skills ?? []),
    ] as ProfileSkill[];

    const traineeSkills = (profile?.skills as ISkill[])
      ?.filter((skill) => skill.sought)
      .map((skill) => {
        const label =
          allSkills.find((s) => s.slug === skill.fieldSlug)?.label ?? undefined;
        return label;
      })
      .join(', ');

    const guideSkills = (profile?.skills as ISkill[])
      ?.filter((skill) => skill.offered)
      .map((skill) => {
        const label =
          allSkills.find((s) => s.slug === skill.fieldSlug)?.label ?? undefined;

        return label;
      })
      .join(', ');

    const bio = profile?.bio;

    const rows = relationships
      .filter((relationship) => !relationship.programSlug)
      .flatMap(
        (relationship) => {
          const matchProfile = relationship.traineeProfiles?.find(
            (p) => p.id === profile.id,
          )
            ? relationship?.guideProfiles?.at(0)
            : relationship?.traineeProfiles?.at(0);

          const matchUser = relationship.users?.find(
            (p) => p.id !== profile.userId,
          );

          const pastSessions =
            relationship?.sessions
              ?.slice()
              .filter(
                (session) =>
                  !isAfter(new Date(session.start ?? ''), new Date()),
              ) ?? [];

          return {
            id: matchProfile?.id,
            blobPicture: matchProfile?.picture,
            auth0Picture: matchUser?.picture,
            name: matchProfile?.displayName,
            description: relationship?.createdAt
              ? format(new Date(relationship?.createdAt), 'dd/MM/yy')
              : 'N/A',
            numberOfSessions: pastSessions?.length?.toString() || '0',
            status: relationship?.isConcluded,
            relationshipId: relationship?.id,
            title: matchProfile?.jobTitle,
            onClick: () => navigate(`/users/${matchProfile?.id}`),
          };
        },
        [profile],
      );

    return {
      guideSkills,
      traineeSkills,
      bio,
      roles,
      rows,
      guideRole,
      traineeRole,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, organization, profile, relationships]);

  async function handleCreateRelationship(id: string) {
    const result = await reqAdminRelationships({
      method: 'POST',
      url: isRootDomain ? '/superadmin/relationships' : '/admin/relationships',
      // TODO fix types
      // @ts-ignore
      data: {
        guideProfiles: [id],
        traineeProfiles: [profile?.id],
        organizationSlug: organizationSlug ?? profile?.organizationSlug,
        programTypeSlug:
          organization?.open_matching?.program_type?.program_type_slug,
        programVariationTypeSlug: EProgramVariation.Individual,
        profileId: profile?.id,
      },
    });

    if (result.status === 'success') {
      reqAdminRelationships({
        url: isRootDomain
          ? '/superadmin/relationships'
          : '/admin/relationships',
        params: {
          organizationSlug: organizationSlug ?? profile?.organizationSlug,
          profileId: profile?.id,
        },
      });
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      <CollapsibleCard
        headerElement={
          <AvatarBox
            size="medium"
            avatarSrc={buildSanityImageUrl({
              source: organization?.open_matching?.details?.image ?? '',
            })}
            heading={organization?.open_matching?.details?.title}
          />
        }
        sx={{
          borderRadius: {
            xs: 0,
            md: '8px',
            boxShadow: '0px 8px 16px 0px #00000026',
          },
        }}
      >
        <Stack direction={{ xs: 'column', lg: 'row' }} px={4.5} py={5} gap={3}>
          <Stack
            direction={'column'}
            justifyContent={'flex-start'}
            sx={{ textAlign: 'left' }}
          >
            <ProgramMembershipDetailsView
              title={'Profile'}
              chips={[
                {
                  variant: 'contained',
                  label: profileData?.guideRole ?? 'Mentor',
                  color: 'dark',
                  position: 'right',
                  sx: {
                    display: profileData?.roles?.includes('guide')
                      ? ''
                      : 'none',
                  },
                },

                {
                  variant: 'contained',
                  label: profileData?.traineeRole ?? 'Mentee',
                  color: 'info',
                  position: 'right',
                  sx: {
                    display: profileData?.roles?.includes('trainee')
                      ? ''
                      : 'none',
                  },
                },
              ]}
              fields={[
                profileData?.roles?.includes('trainee')
                  ? {
                      label: 'Skills sought',
                      value:
                        profileData?.traineeSkills &&
                        profileData?.traineeSkills !== ''
                          ? profileData?.traineeSkills
                          : 'No skills set',
                    }
                  : undefined,
                profileData?.roles?.includes('guide')
                  ? {
                      label: 'Skills offered',
                      value:
                        profileData?.guideSkills &&
                        profileData?.guideSkills !== ''
                          ? profileData?.guideSkills
                          : 'No skills set',
                    }
                  : undefined,
                {
                  label: 'Bio',
                  value:
                    profileData?.bio && profileData?.bio !== ''
                      ? profileData?.bio
                      : 'No bio set',
                },
              ]}
            />
            <ButtonStack
              mt={1.5}
              spacing={0}
              gap={1.5}
              direction={{ xs: 'column', md: 'row' }}
              width={'100%'}
              buttons={[]}
            />
          </Stack>

          <Stack direction={'column'} flex={1} spacing={0} gap={1}>
            {profileData?.roles?.includes('trainee') && (
              <SuggestedMatchesContainer
                onCreateManualMatch={handleCreateRelationship}
                openMatching
                isManualMatchRequestPending={isLoadingAdminRelationships()}
                roleName={profileData?.guideRole}
              />
            )}

            {profileData && profileData?.rows.length !== 0 ? (
              <RelationshipsTableContainer
                userOrganizationSlug={profile?.organizationSlug}
                rows={profileData.rows as IRelationshipsRow[]}
                headers={{
                  name: 'Name',
                  status: 'Relationship status',
                  numberOfSessions: 'Sessions held',
                  manage: 'Manage',
                }}
              />
            ) : (
              <ProgramMembershipEmptyTableView heading={'No relationships'} />
            )}
          </Stack>
        </Stack>
      </CollapsibleCard>
    </Stack>
  );
}
