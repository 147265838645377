import {
  getFormattedProgramFields,
  getFormattedSkills,
} from '@guider-global/front-end-utils';
import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import { IProfileProgramOverview, Program } from '@guider-global/shared-types';
import { format } from 'date-fns';
import {
  ProgramMembershipsGuideContent,
  ProgramMembershipsTraineeContent,
  ProgramMembershipsCard,
  UserData,
} from '../../../views/ProgramMembershipsCard';
import { IRelationshipsRow } from '../RelationshipsTableContainer';
import { useMemo } from 'react';
import { ProgramMembershipDetailsViewProps } from '@guider-global/ui';

export type GroupProgramMembershipsContainerProps = {
  profileProgramOverview: IProfileProgramOverview;
  program: Program;
  userData: UserData;
};

export function GroupProgramMembershipsContainer({
  profileProgramOverview,
  program,
  userData,
}: GroupProgramMembershipsContainerProps) {
  const memberships = profileProgramOverview.memberships;
  const relationshipProfilesData = memberships.flatMap(
    (membership) => membership.relationshipProfiles,
  );

  const programData = useMemo(() => {
    const guideMembership = memberships
      .filter(
        (membership) =>
          profileProgramOverview.programSlug === program?.metadata.id.current &&
          membership.role === 'guide',
      )
      .at(0);

    const traineeMembership = memberships
      .filter(
        (membership) =>
          profileProgramOverview.programName ===
            program?.metadata.program_name && membership.role === 'trainee',
      )
      .at(0);

    const guideRelationshipProfiles = guideMembership
      ? relationshipProfilesData.filter((profile) =>
          guideMembership.relationshipProfiles.includes(profile),
        )
      : [];

    const traineeRelationshipProfiles = traineeMembership
      ? relationshipProfilesData.filter((profile) =>
          traineeMembership.relationshipProfiles.includes(profile),
        )
      : [];

    const programAvatar = buildSanityImageUrl({
      source: program?.program_details?.program_image.asset ?? '',
      width: 300,
    });
    const programCardTitle = program?.metadata.program_name || '';

    const guideCount = guideRelationshipProfiles.length;
    const traineeCount = traineeRelationshipProfiles.length;

    const guideCountLabel = `${guideCount} ${
      guideCount === 1 ? 'Mentor' : 'Mentors'
    }`;
    const traineeCountLabel = `${traineeCount} ${
      traineeCount === 1 ? 'Trainee' : 'Trainees'
    }`;

    const programCardDescription = `${guideCountLabel} • ${traineeCountLabel}`;

    return {
      programAvatar,
      programCardTitle,
      programCardDescription,
      traineeMembership,
      guideMembership,
    };
  }, [
    memberships,
    relationshipProfilesData,
    program?.program_details?.program_image.asset,
    program?.metadata.program_name,
    program?.metadata.id,
    profileProgramOverview.programSlug,
    profileProgramOverview.programName,
  ]);
  const {
    programAvatar,
    programCardTitle,
    traineeMembership,
    guideMembership,
  } = programData;
  // Trainee Membership
  const traineeMembershipData: ProgramMembershipsTraineeContent =
    useMemo(() => {
      //Trainee membership fields
      const formattedTraineeMembershipCreated = traineeMembership?.createdAt
        ? format(new Date(traineeMembership.createdAt), 'dd/MM/yy')
        : 'N/A';

      const traineeSkills = getFormattedSkills(
        'trainee',
        traineeMembership?.skills,
        program.registration?.skills,
      );
      const traineeFields = getFormattedProgramFields(
        traineeMembership,
        program,
      );

      traineeFields.unshift(traineeSkills);

      const traineeDetailsTitle = 'Mentee profile';
      const traineeDetailsDescription = traineeMembership
        ? `Profile Created: ${formattedTraineeMembershipCreated}`
        : '';

      const traineeMembershipDetails: ProgramMembershipDetailsViewProps = {
        title: traineeDetailsTitle,
        description: traineeDetailsDescription,
        fields: traineeFields,
      };

      let traineeRelationshipRows: IRelationshipsRow[] = [];

      if (
        traineeMembership &&
        traineeMembership.relationshipProfiles.length > 0
      ) {
        const relationshipProfile = traineeMembership.relationshipProfiles[0]; // Accessing the first relationship profile
        const {
          displayName,
          sessionCount,
          isConcluded,
          auth0Picture,
          createdAt,
          relationshipId,
          id,
          title,
          picture,
        } = relationshipProfile;

        traineeRelationshipRows = [
          {
            id: id,
            auth0Picture: auth0Picture,
            blobPicture: picture,
            name: title || displayName,
            description: createdAt
              ? format(new Date(createdAt), 'dd/MM/yy')
              : 'N/A',
            numberOfSessions: sessionCount?.toString() || '0',
            status: isConcluded,
            relationshipId: relationshipId,
            title: title,
          },
        ];
      }
      return {
        traineeMembershipDetails,
        traineeRelationshipRows,
        traineeTableEmptyState: 'The user has not joined any group',
      };
    }, [program, traineeMembership]);

  const guideMembershipData: ProgramMembershipsGuideContent = useMemo(() => {
    const formattedGuideMembershipCreated = guideMembership?.createdAt
      ? format(new Date(guideMembership.createdAt), 'dd/MM/yy')
      : 'N/A';

    const guideSkills = getFormattedSkills(
      'guide',
      guideMembership?.skills,
      program.registration?.skills,
    );
    const guideFields = getFormattedProgramFields(guideMembership, program);

    guideFields.unshift(guideSkills);

    const guideDetailsTitle = 'Mentor profile';
    const guideDetailsDescription = guideMembership
      ? `Profile Created: ${formattedGuideMembershipCreated}`
      : '';

    const guideMembershipDetails: ProgramMembershipDetailsViewProps = {
      title: guideDetailsTitle,
      description: guideDetailsDescription,
      fields: guideFields,
    };

    let guideRelationshipRows: IRelationshipsRow[] = [];

    if (guideMembership && guideMembership.relationshipProfiles.length > 0) {
      const relationshipProfile = guideMembership.relationshipProfiles[0];
      const {
        displayName,
        sessionCount,
        isConcluded,
        auth0Picture,
        createdAt,
        relationshipId,
        id,
        title,
        picture,
      } = relationshipProfile;

      guideRelationshipRows = [
        {
          id: id,
          auth0Picture: auth0Picture,
          blobPicture: picture,
          name: title || displayName,
          description: createdAt
            ? format(new Date(createdAt), 'dd/MM/yy')
            : 'N/A',
          numberOfSessions: sessionCount?.toString() || '0',
          status: isConcluded,
          relationshipId: relationshipId,
          title: title,
        },
      ];
    }
    const guideMembershipStatus = {
      isPublished: !!guideMembership?.isPublished,
      isBlocked: !!guideMembership?.isBlocked || false,
      requiresApproval: !!guideMembership?.requiresApproval || false,
    };
    return {
      guideMembershipDetails,
      guideRelationshipRows,
      guideTableEmptyState: 'The user has not created any group yet',
      guideMembershipStatus: guideMembershipStatus,
    };
  }, [guideMembership, program]);

  const groupTableHeaders = {
    name: 'Group name',
    status: 'Relationship Status',
    numberOfSessions: 'Session held',
    manage: 'Manage',
  };
  return (
    <ProgramMembershipsCard
      profileProgramOverview={profileProgramOverview}
      title={programCardTitle}
      description={''}
      avatarSrc={programAvatar}
      guideMembership={guideMembership}
      traineeMembership={traineeMembership}
      userData={userData}
      guideMembershipContent={guideMembershipData}
      traineeMembershipContent={traineeMembershipData}
      guideRelationshipTableHeaders={groupTableHeaders}
      traineeRelationshipTableHeaders={groupTableHeaders}
    />
  );
}
